<template>
  <vs-card>
    <div slot="header">
      <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
        <vs-col vs-w="6">
          <h5 class="pt-2">Create Discount</h5>
        </vs-col>
        <vs-col vs-w="6" class="flex justify-end">
          <vs-button
            class="py-2 px-4 mr-2"
            @click="onSave">Save</vs-button>
          <vs-button
            class="py-2 px-4"
            @click="cancelHandler" type="border">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <discount-details
      :details="details"
      :saving="isSave"
      @handleSave="handleSave">
    </discount-details>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import DiscountDetails from "@/views/components/WebStore/discount/Details.vue"
import moment from "moment";

export default {
  name: 'CreateDiscount',
  components: {
    DiscountDetails
  },
  data() {
    return {
      details: {
        discount_name: "",
        clinic_is_equal: false,
        product_is_equal: false,
        rule_valid_from: moment().format('YYYY-MM-DD'),
        rule_valid_to: moment().add(7, 'days').format('YYYY-MM-DD'),
        isTierCosting: false,
        discount_rules: [],
        products: [],
        clinics: [],
        group: null,
        groupId: null
      },
      isSave: false
    }
  },
  methods: {
    ...mapActions('discount', [
      "createBulkDiscount"
    ]),
    onSave() {
      this.isSave = true

      setTimeout(() => {
        this.isSave = false
      }, 1000)
    },
    cancelHandler() {},
    async handleSave(data) {
      this.$vs.loading();

      try {
        await this.createBulkDiscount(data)

        this.$vs.notify({
          title: "Success",
          text: "Discount added successfully",
          color: "success",
        });

        this.$vs.loading.close();

        this.goToList()
      } catch (err) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to create discount.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    goToList() {
      this.$router.push({ name: 'superAdminStoreDiscount' });
    }
  }
}
</script>